import FluroContent from "./fluro-content";

class Faq extends FluroContent {
    constructor() {
        super("faq");
    }
}

const _Faq = new Faq();
export default _Faq;
