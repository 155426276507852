<template>
    <div class="faqs full-height full-width">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <template v-if="faqs && faqs.length">
                <v-container fluid class="pa-6 overflow-y-scroll full-height full-width p-relative">
                    <div class="wrapper text-center">
                        <v-row>
                            <v-col cols="12">
                                <h1>Frequently Asked Questions</h1>
                                <p class="ma-0">Have a question? We're here to help</p>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
                                <v-text-field
                                    ref="search"
                                    class="field mb-6"
                                    dense
                                    hide-details
                                    filled
                                    solo
                                    flat
                                    prepend-inner-icon="mdi-magnify"
                                    v-model="search"
                                    label=""
                                    placeholder="Search FAQs..."
                                    type="text"
                                >
                                    <template v-slot:append>
                                        <span class="styled-button">
                                            <v-icon x-small class="mr-1">mdi-apple-keyboard-command</v-icon>K
                                        </span>
                                    </template>
                                </v-text-field>

                                <template v-if="filtered.length">
                                    <v-expansion-panels v-model="expanded" accordion>
                                        <v-expansion-panel v-for="item in filtered" :key="item._id">
                                            <v-expansion-panel-header
                                                ><b>{{ item.title }}</b></v-expansion-panel-header
                                            >

                                            <v-expansion-panel-content>
                                                <div v-html="item.body" class="text-left"></div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </template>

                                <template v-else>
                                    <v-container fluid class="pa-6 full-height full-width">
                                        <v-row class="full-height full-width">
                                            <v-col class="full-height full-width" cols="12">
                                                <div class="full-height full-width d-flex align-center justify-center">
                                                    <div class="d-flex flex-column align-center">
                                                        <p class="text-center">
                                                            No FAQs matching <b class="primary--text">{{ search }}</b
                                                            >.
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </template>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <v-container fluid class="pa-6 full-height full-width">
                    <v-row class="full-height full-width">
                        <v-col class="full-height full-width" cols="12">
                            <div class="full-height full-width d-flex align-center justify-center">
                                <div class="d-flex flex-column align-center">
                                    <p class="text-center">
                                        <b>No FAQs</b>
                                    </p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </template>
    </div>
</template>

<script>
// Services
import Faq from "@/modules/app-module/faq";

export default {
    name: "faqs",

    meta: {
        title: "FAQs",
    },

    props: {},

    components: {},

    data() {
        return {
            loading: false,
            faqs: [],
            search: "",
            expanded: null,
        };
    },

    computed: {
        filtered() {
            let faqs = this.faqs;

            let keywords = this.search.toLowerCase();

            if (keywords && keywords.length) {
                faqs = faqs.filter((faq) => {
                    let title = faq.title.toLowerCase();
                    let body = faq.body.toLowerCase();

                    return title.includes(keywords) || body.includes(keywords);
                });
            }

            return faqs;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            await this.$fluro.resetCache();
            console.log(this.$app.publicRealm);
            this.faqs = await Faq.list().then(({ data }) => data);

            console.log("FAQS BEFORE", this.faqs);
            this.faqs = this.faqs.filter((item) =>
                item.realms.some((realm) => realm._id == this.$app.liveRealm || realm._id == "627b16eb37396f06dadbd0d1")
            );
            console.log("FAQS AFTER", this.faqs);

            this.loading = false;
        },
        handleKeydown(e) {
            if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode == 75) {
                e.preventDefault();
                this.$refs.search.focus();
            }
        },
    },

    watch: {
        "filtered.length": function (v) {
            if (v == 1) {
                this.expanded = 0;
            } else {
                this.expanded = null;
            }
        },
    },

    async mounted() {
        await this.init();
    },

    created() {
        document.addEventListener("keydown", this.handleKeydown, false);
    },

    destroyed() {
        document.removeEventListener("keydown", this.handleKeydown, false);
    },
};
</script>

<style lang="scss"></style>
